<template>
  <card-component title="Edit Profile" icon="account-circle">
    <form @submit.prevent="submit">
      <b-field horizontal label="Avatar">
        <user-avatar :avatar="avatarUrl" class="image has-max-width" />
        <ImagePicker :id="form.id" :imageType="imageType" @new-image="newImage" />
      </b-field>
      <hr />

      <b-field horizontal label="Name" message="Required. Your name">
        <b-input v-model="form.firstName" required disabled />
        <b-input v-model="form.lastName" required disabled />
      </b-field>
      <b-field horizontal label="E-mail" message="Required. Your e-mail">
        <b-input v-model="form.email" name="email" type="email" required disabled />
      </b-field>
      <hr />
      <!-- <b-field horizontal>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }">
            Submit
          </button>
        </div>
      </b-field> -->
    </form>
  </card-component>
</template>

<script>
import { mapState } from "vuex";
import ImagePicker from "@/components/ImagePicker";
import UserAvatar from "@/components/UserAvatar";
import CardComponent from "@/components/CardComponent";
import ImageTypeEnum from "@/enums/imageType";

export default {
  name: "ProfileUpdateForm",
  components: {
    CardComponent,
    ImagePicker,
    UserAvatar
  },
  data() {
    return {
      isFileUploaded: false,
      isLoading: false,
      imageType: ImageTypeEnum.Type.Profile,
      form: {
        id: this.$store.getters.userId,
        firstName: this.$store.getters.firstName,
        lastName: this.$store.getters.lastName,
        email: this.$store.getters.email,
        avatar: this.$store.getters.avatar
      },
      avatarUrl: this.$store.getters.avatarUrl // Had problem using form.avatar as it didn't seem to update
    };
  },
  computed: {
    ...mapState(["userName", "userEmail"])
  },
  // watch: {
  //   userName(newValue) {
  //     this.form.name = newValue;
  //   },
  //   userEmail(newValue) {
  //     this.form.email = newValue;
  //   }
  // },
  // mounted() {
  //   this.form.name = this.userName;
  //   this.form.email = this.userEmail;
  // },
  methods: {
    submit() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.$store.commit("user", this.form);
        this.$buefy.snackbar.open({
          message: "Updated",
          queue: false
        });
      }, 500);
    },
    newImage(newImage) {
      this.form.avatar = newImage.fullFileName;
      this.avatarUrl = newImage.url;
      this.$store.dispatch("setAvatar", newImage);
    }
  }
};
</script>
